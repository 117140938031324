<template>
    <div class="rechargeRecords" :style="rechargeRecords">
        <common-nav-bar title="购买记录"></common-nav-bar>
        <div class="reports">
            <van-row class="reportsTitle textC">
                <van-col span="5">设备号</van-col>
                <van-col span="5">金额/元</van-col>
                <van-col span="5">购买日期</van-col>
                <van-col span="4">购买方式</van-col>
                <van-col span="5">购买状态</van-col>
            </van-row>
            <van-list class="recordsList">
                <van-row class="reportsList textC" v-for="(item, i) in orders" :key="i">
                    <van-col span="5">{{ item.sn }}</van-col>
                    <van-col span="5">{{ item.amount / 100 }}</van-col>
                    <van-col span="5">{{ formatDay(item.createdAt) }}</van-col>
                    <van-col span="4">{{ rechargeTypes[item.from] }}</van-col>
                    <van-col
                        @click="clickStatus(item)"
                        span="5"
                        :style="statusMap[item.status].color"
                    >
                        {{ statusMap[item.status].label }}
                        <!-- <van-button
                        round
                        size="mini"
                        plain
                        color="#D0021B"
                        type="info"
                        >待支付</van-button> -->
                    </van-col>
                </van-row>
            </van-list>
        </div>
        <div class="rechargeTip">未支付购买订单将在30分钟后自动取消，请及时完成支付！</div>
    </div>
</template>

<script>
import { getOrders } from '@/services/order';
import { toast,formatDate } from '@/util';
import { createWxPrepay, getPaymentByPaymentNo } from '@/services/payment';
import CommonNavBar from '@/components/common-nav-bar';
import { WX_APPID } from '../../../config';

const rechargeTypes = [null, '微信支付', '后台支付'];
const statusMap = {
    10: { color: 'color:red', label: '待支付' },
    20: { color: 'color:green', label: '支付成功' },
    30: { color: '', label: '已取消' },
};

export default {
    name: 'rechargeRecords',
    components: { CommonNavBar },
    data() {
        return {
            orders: [],
            rechargeRecords: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                minHeight: '100%',
                backgroundAttachment: 'fixed',
            },
            rechargeTypes,
            statusMap,
        };
    },
    created() {
        this.getOrders();
    },
    methods: {
        async getOrders() {
            const { code, message, data } = await getOrders();
            if (!code) {
                return toast(message);
            }
            this.orders = data;
        },
        formatDay(day) {
            return formatDate(day, 'yyyy/MM/dd');
        },
        async clickStatus(order) {
            if (order.status !== 10) {
                return;
            }
            await this.createWxPrepay(order.id);
        },
        async createWxPrepay(orderId) {
            const { code, message, data } = await createWxPrepay({ orderId });
            if (!code) {
                return toast(message);
            }
            const { payment, bridgeConfig } = data;
            this.onBridgeReady(payment, { ...bridgeConfig, appId: WX_APPID, timeStamp: bridgeConfig.timestamp, });
        },
        onBridgeReady(payment, data) {
            const that = this;
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke('getBrandWCPayRequest', data, async function (res) {
                if (res.err_msg === 'get_brand_wcpay_request:ok') {
                    const { code, message, data } = await getPaymentByPaymentNo(payment.paymentNo);
                    if (!code) {
                        return toast(message);
                    }
                    if (data && data.status === 2) {
                        toast('支付成功');
                        await that.getOrders();
                        return;
                    }
                    return toast('支付失败');
                } else {
                    toast('取消支付');
                }
            });
        },
    },
};
</script>

<style scoped>
.reports {
    padding-top: 40px;
    padding-bottom: 37px;
}
.testReports .van-cell::after {
    border-color: #ccc;
}

.reportsTip {
    font-size: 10px;
    color: #a3a3a3;
}

.reportsTitle {
    font-size: 14px;
    color: #282a2d;
    line-height: 40px;
    background: #fff;
    position: fixed;
    top: 46px;
    width: 100%;
    z-index: 2;
    box-shadow: 0 2px 5px #ccc;
    font-weight: 600;
}

.reportsTitle .van-cell {
    padding: 0;
    font-size: 14px;
    color: #282a2d;
    line-height: 40px;
}

.reportsTitle .van-cell .van-image {
    margin-left: 5px;
    position: relative;
    top: -3px;
}

.reportsList {
    font-size: 14px;
    color: #6d7278;
    line-height: 35px;
    /* background: rgba(255, 255, 255, 0.7); */
    padding: 5px 0;
    position: relative;
}

.reportsList ::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    border-bottom: 1px solid #ccc;
    display: block;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
    font-size: 10px;
}
.reportsList .van-button--mini {
    padding: 0 10px;
    vertical-align: middle;
}
.rechargeTip {
    color: #d0021b;
    font-size: 12px;
    padding: 10px;
    border-top: 1px solid #fefefe;
    background: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
}
.recordsList{
    margin-top: 46px;
}
</style>
