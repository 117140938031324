import { Method, request, Url } from '@/services/base';

async function createWxPrepay({ orderId }) {
    return await request(Url.payment.createWxPrepay, Method.post, { orderId });
}

async function getPaymentByPaymentNo(paymentNo) {
    return await request(Url.payment.getPaymentByPaymentNo, Method.get, { paymentNo });
}

export { createWxPrepay, getPaymentByPaymentNo };
