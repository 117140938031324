import { Method, request, Url } from '@/services/base';

async function getLatestOrder() {
    return await request(Url.order.getLatestOrder, Method.get);
}

async function getOrders() {
    return await request(Url.order.getOrders, Method.get);
}

async function createOrder({ sn, goodsId }) {
    return await request(Url.order.createOrder, Method.post, { sn, goodsId });
}

export { getLatestOrder, getOrders, createOrder };
